import HeaderBlok from 'bloks/HeaderBlok';
import { useEffect, useRef, useState } from 'react';
import {
    ExtractSubRouteName,
    ValidateRoute,
    changeExtractionRoute,
    extractionRouteSelector,
} from 'state/router';
import { useAppDispatch, useAppSelector } from 'state/store';
import Choose from 'widgets/Choose';
import './validate.css';
import Button from 'widgets/Button';

const maxFileNameLength = 12;

const Validate = () => {
    const dispatch = useAppDispatch();

    const { extraction } = useAppSelector(
        extractionRouteSelector
    ) as ValidateRoute;

    const allFileNames = Object.keys(extraction);

    const [currentFileName, setCurrentFileName] = useState(allFileNames[0]);
    const [currentPage, setCurrentPage] = useState(0);
    const [markedAsFinished, setMarkedAsFinished] = useState(() => {
        let data = {};
        Object.keys(extraction).forEach(
            (fileName) =>
                (data[fileName] = new Array(
                    extraction[fileName].pages.length
                ).fill(false))
        );

        return data as { [fileName: string]: Array<boolean> };
    });

    const numberOfPages = extraction[currentFileName].pages.length;
    const columnsOnPageChooser = numberOfPages > 4 ? 4 : numberOfPages;

    const fileIsFinished = (fileName) =>
        markedAsFinished[fileName].every((v) => v);

    const isDone = allFileNames.every((fileName) => fileIsFinished(fileName));

    const markAsFisnished = (fileName, pageNumber) => {
        markedAsFinished[fileName][pageNumber] = true;
        setMarkedAsFinished({
            ...markedAsFinished,
            [fileName]: Object.assign([], markedAsFinished[fileName], {
                [pageNumber]: true,
            }),
        });
    };

    const fileOptions = Object.fromEntries(
        allFileNames.map((fileName) => {
            let fileNameShorter =
                fileName.length < maxFileNameLength
                    ? fileName
                    : fileName.slice(0, maxFileNameLength) + ' ...';

            const fileNameChecked = fileIsFinished(fileName);

            if (fileNameChecked) {
                fileNameShorter += ' ✔';
            }

            return [fileName, fileNameShorter];
        })
    );

    const pageOptions = Object.fromEntries(
        Array.from(
            {
                length: numberOfPages,
            },
            (_, i) => [
                i,
                markedAsFinished[currentFileName][i] ? i.toString() + ' ✔' : i,
            ]
        )
    );

    const goToNextPage = () => {
        if (!isDone && !window.confirm('Are you sure all data is verified?')) {
            return;
        }
        dispatch(changeExtractionRoute({ name: ExtractSubRouteName.Options }));
    };

    let cleanUpListeners = useRef(null as any); // making sure only one is lisnening to messages

    const popupMessageListener =
        (fileName, pageNumber, windowToClose?) => (e) => {
            console.log('messssssssage', e.data);
            if (e.data == 'markedAsFinished') {
                markAsFisnished(fileName, pageNumber);
                windowToClose && window.close();
            }
        };

    useEffect(() => {
        const listener = popupMessageListener(currentFileName, currentPage);
        cleanUpListeners.current && cleanUpListeners.current();
        window.addEventListener('message', listener);
        cleanUpListeners.current = () =>
            window.removeEventListener('message', listener);
    }, [currentFileName, currentPage, markedAsFinished]);

    const changeCurrentFileName = (toFileName: string) => {
        setCurrentFileName(toFileName);
        if (extraction[toFileName].pages.length == 1) {
            const popupWindow = window.open(
                (extraction[toFileName].pages[0] as { validationUrl: string })
                    .validationUrl
            );

            markAsFisnished(toFileName, 0);

            popupWindow.addEventListener(
                'message',
                popupMessageListener(toFileName, 0)
            );
        }
    };

    const changeCurrentPage = (toPageNumberAsString: string) => {
        const toPageNumber = parseInt(toPageNumberAsString);
        setCurrentPage(toPageNumber);
        const popupWindow = window.open(
            (
                extraction[currentFileName].pages[toPageNumber] as {
                    validationUrl: string;
                }
            ).validationUrl
        );

        markAsFisnished(currentFileName, toPageNumber);

        popupWindow.addEventListener(
            'message',
            popupMessageListener(currentFileName, toPageNumber)
        );
    };

    return (
        <div className="Validate">
            <HeaderBlok title="Validating Files">
                <div className="Validate-explanation">
                    On this page you are going to correct our model on its
                    mistakes. For every field it tries to find the box in which
                    this fields value can be found. Sometimes this box is in an
                    incorrect place, or it's not found at all. Click on one of
                    the files/pages and correct these boxes by dragging them to
                    the right spot. Once done, you click on 'Approve file', and
                    close the tab. Then click on the next file or page and
                    repeat.
                    <br />
                    <br /> If the screen gets stuck at "Processing Image",
                    reload the popup page or click on the same file.
                </div>
            </HeaderBlok>
            <div className="Validate-options">
                <HeaderBlok title="File">
                    <div className="File-scroll">
                        <Choose
                            value={currentFileName}
                            setValue={changeCurrentFileName}
                            options={fileOptions}
                            numberOfColumns={1}
                            canBeNone={false}
                        ></Choose>
                    </div>
                </HeaderBlok>

                {numberOfPages > 1 && (
                    <HeaderBlok title="Page">
                        <Choose
                            value={currentPage}
                            setValue={changeCurrentPage}
                            options={pageOptions}
                            numberOfColumns={columnsOnPageChooser}
                            canBeNone={false}
                        ></Choose>
                    </HeaderBlok>
                )}

                {/* <div className="Validate-markAsFinished">
                    <Button
                        onClick={() =>
                            markAsFisnished(currentFileName, currentPage)
                        }
                    >
                        Mark as Done
                    </Button>
                </div> */}

                {/* {isDone && ( */}
                <div className="Validate-markAsFinished">
                    <Button onClick={() => goToNextPage()}>Go Home</Button>
                </div>
                {/* )} */}
            </div>
        </div>
    );
};

export default Validate;
