import { useState } from 'react';
import './Options.css';

import Files from './Files';
import Submit from './Submit';
import ErrorBlok from 'bloks/ErrorBlok';
import DocumentType from './DocumentType';
import { useAppDispatch, useAppSelector } from 'state/store';
import { ExtractionResponse, useRequest } from 'api';
import {
    allDocumentTypesSelector,
    changeExtractionRoute,
    ExtractSubRouteName,
} from 'state/router';
import { errorSelector } from 'state/errors';
import HeaderBlok from 'bloks/HeaderBlok';
import Choose from 'widgets/Choose';
import { addFileNamePrefix, removeFileNamePrefix } from 'utils';

function Options() {
    const [files, setFiles] = useState(null as Array<File>);
    const [chooseFolder, setChooseFolder] = useState(false);
    const [fields, setFields] = useState({});
    const [documentType, setDocumentType] = useState('BL');
    const allDocumentTypes = useAppSelector(allDocumentTypesSelector);
    const [extractOrTrain, setExtractOrTrain] = useState('extract');
    const [oneShotModelOrNot, setOneShotModelOrNot] = useState('no');

    const dispatch = useAppDispatch();
    const error = useAppSelector(errorSelector);
    const request = useRequest();

    const getFields = (documentType) =>
        request('get', 'fields', { documentType }).then(
            async (res) => res && setFields(await res.json())
        );

    const extractFiles = () => {
        if (files == null) {
            window.alert('You must first provide some files!');
            return;
        }

        if (documentType == null) {
            window.alert('You must select a document type first!');
            return;
        }

        // add prefix to fileName
        let fd = new FormData();
        files.forEach((file) =>
            fd.append(addFileNamePrefix(file.name, documentType), file)
        );

        dispatch(changeExtractionRoute({ name: ExtractSubRouteName.Loading }));

        request(
            'post',
            'extract',
            {
                validate: extractOrTrain === 'train',
                oneShot: oneShotModelOrNot == 'yes',
            },
            fd
        ).then(async (res) => {
            // remove all fileName prefixes again
            const extraction = Object.fromEntries(
                Object.entries(await res.json()).map(
                    ([fileName, fileExtraction]) => [
                        removeFileNamePrefix(fileName),
                        fileExtraction,
                    ]
                )
            ) as ExtractionResponse;

            dispatch(
                extractOrTrain == 'extract'
                    ? changeExtractionRoute({
                          name: ExtractSubRouteName.Editor,
                          extraction,
                          files: Object.fromEntries(
                              files.map((file) => [
                                  file.name,
                                  URL.createObjectURL(file).toString(),
                              ])
                          ),
                          fields:
                              oneShotModelOrNot == 'yes'
                                  ? 'all'
                                  : Object.entries(fields)
                                        .filter(([_, value]) => value)
                                        .map(([name, _]) => name),
                      })
                    : changeExtractionRoute({
                          name: ExtractSubRouteName.Validate,
                          extraction,
                      })
            );
        });
    };

    const changeDocumentType = (to: string) => {
        setDocumentType(to);
        getFields(to);
    };

    return (
        <div className="Form">
            <form className="Form-form">
                <Files
                    setFiles={setFiles}
                    files={files}
                    chooseFolder={chooseFolder}
                    setChooseFolder={setChooseFolder}
                ></Files>
                <DocumentType
                    documentType={documentType}
                    setDocumentType={changeDocumentType}
                    allDocumentTypes={allDocumentTypes}
                ></DocumentType>

                <HeaderBlok title="Do you want to improve the model?">
                    <Choose
                        value={extractOrTrain}
                        setValue={setExtractOrTrain}
                        options={{
                            extract: 'Just Extract',
                            train: 'Train',
                        }}
                        numberOfColumns={2}
                        canBeNone={false}
                    ></Choose>
                </HeaderBlok>

                <HeaderBlok title="Would you like to try the new One Shot model?">
                    <Choose
                        value={oneShotModelOrNot}
                        setValue={setOneShotModelOrNot}
                        options={{
                            no: 'No',
                            yes: 'Yes',
                        }}
                        canBeNone={false}
                    ></Choose>
                </HeaderBlok>

                <ErrorBlok>{error}</ErrorBlok>
            </form>
            <Submit files={files} onFormSubmit={extractFiles}></Submit>
        </div>
    );
}

export default Options;
