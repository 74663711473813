import { useRequest, useLoadWithSetter } from "api";
import HeaderBlok from "bloks/HeaderBlok";
import { useEffect, useState } from "react";
import { tokenSelector } from "state/router";
import { useAppSelector } from "state/store";
import Button from "widgets/Button";
import TextInput from "widgets/TextInput";
import restoreIcon from "icons/restore.png";
import { camelToeToSpaced } from "utils";

export default function UserInfo() {
    const token = useAppSelector(tokenSelector);
    const request = useRequest();

    const [newInfo, setNewInfo] = useState({});

    const [info, setInfo] = useLoadWithSetter(
        request("get", "myInfo").then((res) => res.json())
    );

    useEffect(() => {
        info && setNewInfo(info);
    }, [info]);

    const changeInfo = () => {
        if (
            newInfo != info &&
            window.confirm("Are you sure you want to change your info?")
        ) {
            request("post", "myInfo", { token }, newInfo);
            setInfo(newInfo);
        }
    };

    return (
        <HeaderBlok title="My Info">
            <div className="UserInfo">
                {Object.entries(newInfo).map(
                    ([attributeName, attributeValue]) => (
                        <div key={attributeName} className="UserInfo-attribute">
                            <TextInput
                                setValue={(value) =>
                                    setNewInfo({
                                        ...newInfo,
                                        [attributeName]: value,
                                    })
                                }
                                value={attributeValue}
                                label={
                                    <div>
                                        {camelToeToSpaced(attributeName)}
                                        {attributeValue !=
                                            info[attributeName] && (
                                            <img
                                                src={restoreIcon}
                                                onClick={(e) =>
                                                    setNewInfo({
                                                        ...newInfo,
                                                        [attributeName]:
                                                            info[attributeName],
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                }
                            ></TextInput>
                        </div>
                    )
                )}

                <Button onClick={(e) => changeInfo()}>Change Info</Button>
            </div>
        </HeaderBlok>
    );
}
