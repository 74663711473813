import { useState, MouseEvent } from "react";
import { SimpleFileViewer } from "./FileViewer";
import "./Editor.css";
import ButtonBlok from "bloks/ButtonBlok";
import ChooseFile from "./ChooseFile";

import Entries from "./Entries";
import { FieldExtraction, FileExtraction } from "api";
import { useAppDispatch, useAppSelector } from "state/store";
import {
    changeExtractionRoute,
    EditorRoute,
    extractionRouteSelector,
    ExtractSubRouteName,
} from "state/router";
import Validate from "pages/validate/Validate";

function Editor() {

    const { extraction, files, fields } = useAppSelector(
        extractionRouteSelector
    ) as EditorRoute;
    const dispatch = useAppDispatch();

    const [currentFileName, setCurrentFileName] = useState(
        Object.keys(extraction)[0]
    );

    const data = Object.fromEntries(
        Object.entries(extraction).map(([fileName, fileExtraction]) => [
            fileName,
            getFieldsFromExtraction(fileExtraction),
        ])
    );

    const meta = Object.fromEntries(
        Object.entries(extraction).map(([fileName, fileExtraction]) => [
            fileName,
            getMetaFromExtraction(fileExtraction),
        ])
    );

    const onDoneClicked = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (!window.confirm("Have you really finished editing?")) return;

        dispatch(
            changeExtractionRoute({
                name: ExtractSubRouteName.Download,
                data: Object.entries(data).map(([fileName, fileFields]) => {
                    Object.keys(fileFields).forEach((fieldName) => {
                        if (!fields.includes(fieldName)) {
                            delete fileFields[fieldName];
                        }
                    });
                    return { ...fileFields, fileName };
                }),
            })
        );
    };

    return (
        <div className="Editor">
            {/* <FileViewer
                file={files[currentFileName]}
                meta={meta[currentFileName]}
            ></FileViewer> */}
            <SimpleFileViewer
                fileUrl={files[currentFileName]}
            ></SimpleFileViewer>
            <div>
                <ChooseFile
                    files={Object.keys(files)}
                    setFile={setCurrentFileName}
                    initialValue={currentFileName}
                ></ChooseFile>
                <Entries
                    data={data[currentFileName]}
                    fields={fields}
                    meta={meta[currentFileName]}
                ></Entries>
                <ButtonBlok onClick={onDoneClicked}>Done</ButtonBlok>
            </div>
        </div>
    );
}

type FieldData = {
    [label: string]: string;
};

type MetaData = {
    [label: string]: FieldExtraction & {
        pageNumber: number;
        xcenter: number;
        ycenter: number;
    };
};

function getFieldsFromExtraction(data: FileExtraction): FieldData {
    let fieldData = {};
    data.pages.forEach((page) => {
        (page as { fields }).fields.forEach((field) => {
            fieldData[field.label] = field.text;
        });
    });
    return fieldData;
}

function getMetaFromExtraction(data: FileExtraction): MetaData {
    let metaData = {};
    data.pages.forEach((page, pageNumber) => {
        (page as { fields }).fields.forEach((field) => {
            metaData[field.label] = {
                ...field,
                pageNumber: pageNumber,
                xcenter: (field.xmax + field.xmin) / 2,
                ycenter: (field.ymax + field.ymin) / 2,
            };
        });
    });
    return metaData;
}

export default Editor;
export type { FieldData, MetaData };
