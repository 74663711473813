function Loading() {
    return (
        <div className="Loading">
            <div className="Form-spinner" />
            This will take a few seconds...
        </div>
    );
}

export default Loading;
