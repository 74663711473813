import DropDownBlok from "bloks/DropDownBlok";
import { ReactNode, useEffect, useState } from "react";
import Choose from "widgets/Choose";

import trashIcon from "icons/trash.svg";
import Fields from "./Fields";
import TextInput from "widgets/TextInput";
import { useAppDispatch, useAppSelector } from "state/store";
import { useRequest } from "api";
import { allDocumentTypesSelector, setAllDocumentTypes } from "state/router";

export default function Documents() {
    const [newDocumentType, setNewDocumentType] = useState(null as string);
    const dispatch = useAppDispatch();
    const request = useRequest();

    const [fields, setFields] = useState({});
    const [documentType, setDocumentType] = useState(null as string);
    const allDocumentTypes = useAppSelector(allDocumentTypesSelector);

    const getFields = () =>
        request("get", "fields", { documentType }).then(async (res) => {
            res && setFields(await res.json());
        });

    const saveFields = () =>
        request(
            "post",
            "fields",
            { documentType },
            Object.entries(fields)
                .filter(([_, active]) => active)
                .map(([name, _]) => name)
        );

    useEffect(() => {
        documentType && getFields();
    }, [documentType]);

    useEffect(() => {
        fields && saveFields();
    }, [fields]);

    useEffect(() => {
        allDocumentTypes &&
            request("post", "documentTypes", {}, allDocumentTypes);
    }, [allDocumentTypes]);

    let options: { [name: string]: ReactNode } = Object.fromEntries(
        allDocumentTypes.map((name) => [
            name,
            <div className="DocumentType-type">
                {documentType === name ? (
                    <>
                        {name}
                        <img
                            src={trashIcon}
                            alt="-"
                            onClick={(e) => {
                                e.preventDefault();
                                if (
                                    window.confirm(
                                        `Are you use you want to remove document type ${name}?`
                                    )
                                ) {
                                    const rmIndex =
                                        allDocumentTypes.indexOf(name);
                                    if (rmIndex > -1) {
                                        const allDocumentTypesCopy =
                                            Array.from(allDocumentTypes);
                                        allDocumentTypesCopy.splice(rmIndex, 1);
                                        request(
                                            "post",
                                            "documentTypes",
                                            {},
                                            allDocumentTypesCopy
                                        );
                                        dispatch(
                                            setAllDocumentTypes(
                                                allDocumentTypesCopy
                                            )
                                        );
                                    }
                                }
                            }}
                        />
                    </>
                ) : (
                    name
                )}
            </div>,
        ])
    );

    options.new = newDocumentType || "+";

    const changeDocumentType = (to: string) => {
        documentType === "new" &&
            newDocumentType.length > 1 &&
            setAllDocumentTypes([...allDocumentTypes, newDocumentType]);

        documentType === "new" && setNewDocumentType(null);

        to === "new" && setNewDocumentType("");

        setDocumentType(to);
    };

    const onEnterKeyDown = () => {
        newDocumentType.length > 1 &&
            dispatch(
                setAllDocumentTypes([...allDocumentTypes, newDocumentType])
            );
        setNewDocumentType("");
    };

    return (
        <DropDownBlok
            title="Documents"
            startsDropped={true}
            headerChildren={
                <>
                    <Choose
                        value={documentType}
                        setValue={changeDocumentType}
                        options={options}
                    ></Choose>
                    {newDocumentType !== null && newDocumentType !== "new" && (
                        <div className="Documents-newDocInputContainer">
                            <TextInput
                                value={newDocumentType}
                                onEnter={onEnterKeyDown}
                                setValue={setNewDocumentType}
                            ></TextInput>
                        </div>
                    )}
                </>
            }
        >
            <div className="Documents">
                {newDocumentType === null && (
                    <Fields
                        fields={fields}
                        setFields={setFields}
                        documentType={documentType}
                    ></Fields>
                )}
            </div>
        </DropDownBlok>
    );
}
