import 'App.css';
import Header from 'Header';
import Router from 'Router';
import cargoShipImage from 'imgs/cargoShip.png';
import { useEffect } from 'react';
import { useAppSelector } from 'state/store';

window.onbeforeunload;

function App() {
    const route = useAppSelector((state) => state.router);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            window.confirm(
                'Are you sure you want to refresh the page? You will be logged out and changes will not be saved.'
            );
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div className="App">
            {/* {cargoShipImage && (
                <div className="App-bg-container">
                    <img src={cargoShipImage} className="App-background" />
                </div>
            )} */}

            <Header isAuth={route.name !== 'login'}></Header>

            <div className="App-content">
                <Router route={route}></Router>
            </div>
        </div>
    );
}

export default App;
