import { useDispatch } from "react-redux";
import { clearError, setError } from "state/errors";

function Submit({ files, onFormSubmit }) {
    const dispatch = useDispatch();
    return (
        <div className="App-blok Form-submit">
            <div
                className={files ? "App-button" : "App-button-deactive"}
                onClick={
                    files
                        ? (e) => {
                              e.preventDefault();
                              dispatch(clearError());
                              onFormSubmit();
                          }
                        : (e) => {
                              e.preventDefault();
                              dispatch(
                                  setError(
                                      "You must first provide some files before we can convert them 🤓!"
                                  )
                              );
                          }
                }
            >
                Send
            </div>
        </div>
    );
}

export default Submit;
