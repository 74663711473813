import ErrorBlok from "bloks/ErrorBlok";
import downloadIcon from "icons/download.png";
import { MouseEvent, useState } from "react";
import OutputType from "./OutputType";
import { downloadPopup, fileNameFromHeaders } from "./utils";
import "./Download.css";
import { useAppDispatch, useAppSelector } from "state/store";
import { useRequest } from "api";
import {
    changeExtractionRoute,
    extractionRouteSelector,
    DownloadRoute,
    ExtractSubRouteName,
} from "state/router";
import { errorSelector } from "state/errors";

function Download() {
    const [outputType, setOutputType] = useState("csv");
    const [hasDownloaded, setHasDownloaded] = useState(false);

    const request = useRequest();
    const { data } = useAppSelector(extractionRouteSelector) as DownloadRoute;
    const error = useAppSelector(errorSelector);
    const dispatch = useAppDispatch();

    const onGoHome = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (
            hasDownloaded ||
            window.confirm(
                "You sure you want to go back home? You haven't downloaded anything yet!"
            )
        )
            dispatch(
                changeExtractionRoute({ name: ExtractSubRouteName.Options })
            );
    };

    const onDownload = (e: MouseEvent<HTMLDivElement>) => {
        setHasDownloaded(true);
        e.preventDefault();

        request("post", "convert", { outputType }, data).then(
            async (res: Response) => {
                const fileName = fileNameFromHeaders(res);
                const blob = await res.blob();
                downloadPopup(blob, fileName);
            }
        );
    };

    return (
        <div className="Download">
            <OutputType
                outputType={outputType}
                setOutputType={setOutputType}
            ></OutputType>

            <div className="Download-buttons App-blok">
                <div onClick={onDownload} className="App-button">
                    Download <img src={downloadIcon} />
                </div>
                <div onClick={onGoHome} className="App-button">
                    Home
                </div>
            </div>

            <ErrorBlok>{error}</ErrorBlok>
        </div>
    );
}

export default Download;
