interface FieldsProps {
    setFields: (fields: object) => void;
    fields: { [key: string]: boolean };
    documentType: string;
}

function Fields({ setFields, fields, documentType }: FieldsProps) {
    return (
        documentType && (
            <div className="Fields">
                {Object.entries(fields).map(([field, active]) => (
                    <Field
                        name={field}
                        active={active}
                        onClick={(_) =>
                            setFields({ ...fields, [field]: !active })
                        }
                        key={field}
                    ></Field>
                ))}
            </div>
        )
    );
}

function Field({ name, active, onClick }) {
    return (
        <div
            className={"Field " + (active ? "Field-active" : "Field-inactive")}
            onClick={onClick}
        >
            {active ? "✔ " : "✘ "}
            {name}
        </div>
    );
}

export default Fields;
