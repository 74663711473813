export function camelToeToSpaced(text: string): string {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

export function addFileNamePrefix(fileName: string, docType: string): string {
    return docType + "___" + fileName;
}

export function removeFileNamePrefix(fileName: string): string {
    return fileName.substring(fileName.indexOf("___") + 3)
}
