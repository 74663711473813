import { ReactElement, useEffect } from 'react';
import { ExtractionResponse, useRequest } from 'api';
import Dev from 'pages/Dev';
import Download from 'pages/download/Download';
import Editor from 'pages/editor/Editor';
import Error from 'pages/Error';
import Loading from 'widgets/Loading';
import Login from 'pages/login/Login';
import Options from 'pages/options/Options';
import Admin from 'pages/admin/Admin';
import Settings from 'pages/settings/Settings';
import { useAppDispatch } from 'state/store';
import {
    AuthenticatedRoute,
    ExtractSubRoute,
    Route,
    setAdmin,
    setAllDocumentTypes,
} from 'state/router';
import Validate from 'pages/validate/ValidateNew';

type RouterProps = {
    route: Route;
};

function Router({ route }: RouterProps): ReactElement {
    switch (route.name) {
        case 'login':
            return <Login></Login>;
        case 'authenticated':
            return <AuthenticatedRouter route={route}></AuthenticatedRouter>;
    }
}

type AuthenticatedRouterProps = { route: AuthenticatedRoute };

function AuthenticatedRouter({ route }: AuthenticatedRouterProps) {
    const request = useRequest();
    const dispatch = useAppDispatch();

    useEffect(() => {
        request('get', 'admin').then(async (res) => {
            res && (await res.json()) && dispatch(setAdmin());
        });
    }, []);

    useEffect(() => {
        request('get', 'documentTypes').then(
            async (res) =>
                res && dispatch(setAllDocumentTypes(await res.json()))
        );
    }, []);

    switch (route.route) {
        case 'admin':
            return <Admin></Admin>;
        case 'dev':
            return <Dev></Dev>;
        case 'extract':
            return <ExtractRouter route={route.extractRoute}></ExtractRouter>;
        case 'settings':
            return <Settings></Settings>;
    }
}

type ExtractRouterProps = { route: ExtractSubRoute };

function ExtractRouter({ route }: ExtractRouterProps) {
    switch (route.name) {
        case 'options':
            return <Options></Options>;
        case 'loading':
            return <Loading></Loading>;
        case 'editor':
            return <Editor></Editor>;
        case 'validate':
            return <Validate></Validate>;
        case 'error':
            return <Error></Error>;
        case 'download':
            return <Download></Download>;
    }
}

export default Router;
