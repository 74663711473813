import ErrorBlok from "bloks/ErrorBlok";
import { useAppSelector } from "state/store";
import CreateUser from "./CreateUser";
import Usage from "./Usage";
import "./admin.css";
import { errorSelector } from "state/errors";
import { useRequest } from "api";
import Users from "./Users";

export default function Admin() {
    const error = useAppSelector(errorSelector);
    const request = useRequest();
    const userNames = request("get", "users").then((res) => res.json());

    return (
        <div className="Admin">
            <CreateUser></CreateUser>
            <Users userNames={userNames}></Users>
            <Usage userNames={userNames}></Usage>
            <ErrorBlok>{error}</ErrorBlok>
        </div>
    );
}
