import { useRequest } from "api";
import HeaderBlok from "bloks/HeaderBlok";
import { useState } from "react";
import { clearError, setError } from "state/errors";
import { useAppDispatch } from "state/store";
import Button from "widgets/Button";
import Choose from "widgets/Choose";
import NumberInput from "widgets/NumberInput";
import TextInput from "widgets/TextInput";

const minPasswordLength = 5;
const minUserNameLength = 5;

export default function CreateUser() {
    const request = useRequest();
    const dispatch = useAppDispatch();

    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [typeOfUser, setTypeOfUser] = useState("regular");
    const [numberOfTries, setNumberOfTries] = useState(50);

    const createUser = () => {
        if (password !== repeatedPassword) {
            dispatch(setError("Passwords don't line up... 🫣"));
            return;
        }

        if (
            password.length < minPasswordLength ||
            userName.length < minUserNameLength
        ) {
            dispatch(
                setError(
                    `Password and username should be longer than ${minPasswordLength} characters 🙄.`
                )
            );
            return;
        }

        let args: any = { userName, password, typeOfUser };

        if (typeOfUser === "trial") {
            args.numberOfTries = numberOfTries;
        }

        request("get", "createUser", args);

        setPassword("");
        setRepeatedPassword("");
        setUserName("");
        dispatch(clearError());

        window.alert("User created!");
    };


    return (
        <HeaderBlok title="Create User">
            <div className="CreateUser">
                <TextInput
                    label="Username"
                    setValue={setUserName}
                    onEnter={createUser}
                    value={userName}
                ></TextInput>
                <TextInput
                    label="Password"
                    setValue={setPassword}
                    onEnter={createUser}
                    value={password}
                    type="password"
                ></TextInput>
                <TextInput
                    label="Repeat"
                    setValue={setRepeatedPassword}
                    onEnter={createUser}
                    value={repeatedPassword}
                    type="password"
                ></TextInput>
                {typeOfUser === "trial" && (
                    <NumberInput
                        label="Number of Tries"
                        setValue={setNumberOfTries}
                        value={numberOfTries}
                    />
                )}
                <Choose
                    value={typeOfUser}
                    setValue={setTypeOfUser}
                    options={{
                        regular: "regular",
                        trial: "trial",
                        admin: "admin",
                    }}
                />
                <Button onClick={createUser}>Create</Button>
            </div>
        </HeaderBlok>
    );
}
