import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExtractionResponse } from 'api';
import { RootState } from './store';

type Route = LoginRoute | AuthenticatedRoute;
export const enum RouteName {
    Login = 'login',
    Authenticated = 'authenticated',
}

type LoginRoute = { name: RouteName.Login };
type AuthenticatedRoute = {
    name: RouteName.Authenticated;
    admin: boolean;
    token: string;
    extractRoute: ExtractSubRoute;
    allDocumentTypes: Array<string>;
    route: AuthenticatedSubRouteName;
};

export const enum AuthenticatedSubRouteName {
    Extract = 'extract',
    Dev = 'dev',
    Admin = 'admin',
    Settings = 'settings',
}

type ExtractSubRoute =
    | LoadingRoute
    | OptionsRoute
    | EditorRoute
    | DownloadRoute
    | ValidateRoute
    | ErrorRoute;

export const enum ExtractSubRouteName {
    Loading = 'loading',
    Options = 'options',
    Editor = 'editor',
    Error = 'error',
    Download = 'download',
    Validate = 'validate',
}

type ValidateRoute = {
    name: ExtractSubRouteName.Validate;
    extraction: ExtractionResponse;
};
type LoadingRoute = { name: ExtractSubRouteName.Loading };
type OptionsRoute = { name: ExtractSubRouteName.Options };
type EditorRoute = {
    name: ExtractSubRouteName.Editor;
    extraction: ExtractionResponse;
    files: { [filename: string]: string };
    fields: Array<string> | 'all';
};
type ErrorRoute = { name: ExtractSubRouteName.Error; error: string };
type DownloadRoute = {
    name: ExtractSubRouteName.Download;
    data: Array<{ [label: string]: string }>;
};

export type {
    Route,
    LoadingRoute,
    LoginRoute,
    OptionsRoute,
    EditorRoute,
    ErrorRoute,
    DownloadRoute,
    AuthenticatedRoute,
    ExtractSubRoute,
    ValidateRoute,
};

export const routerSlice = createSlice({
    name: 'router',
    initialState: { name: 'login' } as Route,
    reducers: {
        login: (_, action: PayloadAction<string>) => ({
            token: action.payload,
            admin: false,
            name: RouteName.Authenticated,
            extractRoute: { name: ExtractSubRouteName.Options },
            allDocumentTypes: [],
            route: AuthenticatedSubRouteName.Extract,
        }),
        changeRoute: (
            state,
            action: PayloadAction<AuthenticatedSubRouteName>
        ) => ({
            ...(state as AuthenticatedRoute),
            route: action.payload,
        }),
        changeExtractionRoute: (
            state,
            action: PayloadAction<ExtractSubRoute>
        ) => ({
            ...(state as AuthenticatedRoute),
            extractRoute: action.payload,
        }),
        extractionError: (state, action: PayloadAction<string>) => ({
            ...(state as AuthenticatedRoute),
            extractRoute: {
                name: ExtractSubRouteName.Error,
                error: action.payload,
            },
        }),
        setAdmin: (state) => {
            return {
                ...(state as AuthenticatedRoute),
                admin: true,
            };
        },
        setAllDocumentTypes: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...(state as AuthenticatedRoute),
                allDocumentTypes: action.payload,
            };
        },
    },
});

export const {
    login,
    changeRoute,
    changeExtractionRoute,
    setAdmin,
    setAllDocumentTypes,
} = routerSlice.actions;

export const tokenSelector = (state: RootState) =>
    (state.router as AuthenticatedRoute).token;

export const extractionRouteSelector = (state: RootState) =>
    (state.router as AuthenticatedRoute).extractRoute;

export const allDocumentTypesSelector = (state: RootState) =>
    (state.router as AuthenticatedRoute).allDocumentTypes;

export const adminSelector = (state: RootState) =>
    (state.router as AuthenticatedRoute).admin;
