import HeaderBlok from "bloks/HeaderBlok";
import { ReactNode, useContext, useEffect, useState } from "react";
import Choose from "widgets/Choose";

type DocumentTypeProps = {
    documentType: string;
    setDocumentType: (to: string) => void;
    allDocumentTypes: Array<string>;
};

export default function DocumentType({
    documentType,
    setDocumentType,
    allDocumentTypes,
}: DocumentTypeProps) {
    let options: { [name: string]: ReactNode } = Object.fromEntries(
        allDocumentTypes.map((name) => [
            name,
            <div className="DocumentType-type">{name}</div>,
        ])
    );

    return (
        <HeaderBlok title="What type of document do you have?">
            <Choose
                value={documentType}
                setValue={setDocumentType}
                options={options}
            ></Choose>
        </HeaderBlok>
    );
}
