import { ReactNode, useState } from "react";
import upIcon from "icons/up.svg";
import downIcon from "icons/down.svg";
import "./blok.css";

type DropDownBlokProps = {
    title: string;
    children: ReactNode;
};

export default function HeaderBlok({ children, title }: DropDownBlokProps) {
    return (
        <div className="DropDownBlok App-blok App-glass">
            <div className="blok-header">{title}</div>

            <div className="blok-content">{children}</div>
        </div>
    );
}
