import { useRequest } from "api";
import HeaderBlok from "bloks/HeaderBlok";
import { useState } from "react";
import Button from "widgets/Button";

export default function Train() {
    const request = useRequest();
    const [missingAnnotations, setMissingAnnotations] = useState(null);
    const [done, setDone] = useState(false);

    const onTrain = () => {
        setDone(false);
        setMissingAnnotations(null);
        request("get", "train").then(async (res: Response) => {
            const resJson = await res.json();
            if (resJson.done == undefined) {
                setMissingAnnotations(resJson);
                return;
            }

            setDone(true);
        });
    };

    const maxLenLabel = 11;

    return (
        <HeaderBlok title="Train Model">
            <div className="Train">
                <div>
                    If you've validated some files you could improve our
                    extraction model by showing it the right locations of the
                    fields. Because this is a computation intensive task and
                    improvement is only visible after correcting a considerable
                    amount of documents, we don't automatically do this after
                    every validated file. Click this button to actually update
                    the model!
                </div>

                <div className="Train-buttonContainer">
                    <Button onClick={(e) => onTrain()}>
                        {done ? <>Again?</> : <>Train</>}
                    </Button>
                </div>

                {missingAnnotations && (
                    <>
                        Not all fields have the required amount of{" "}
                        {missingAnnotations.neededAnnotations} annotations 🤔.
                        This is how many we still need.
                        <hr />
                        <div className="Settings-missingAnnotations">
                            {missingAnnotations.fields.map(
                                ({ label, count }) => (
                                    <div>
                                        <div className="Settings-missingAnnotationsLabel">
                                            {label.length > maxLenLabel
                                                ? label.slice(0, maxLenLabel) +
                                                  "..."
                                                : label}
                                        </div>
                                        <div className="Settings-missingAnnotationsCount">
                                            {count}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
            </div>
        </HeaderBlok>
    );
}
