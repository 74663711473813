import { HTMLProps, ReactNode } from "react";

type ButtonProps = {
    children: ReactNode;
};

function ButtonBlok(props: ButtonProps & HTMLProps<HTMLDivElement>) {
    return (
        <div className="Button App-blok">
            <div className="App-button" {...props}></div>
        </div>
    );
}

export default ButtonBlok;
