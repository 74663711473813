export default function TextInput({
    setValue,
    onEnter = () => {},
    label = null,
    type = "text",
    value
}) {
    const onEnterKeyDown = (e) => {
        if (e.key === "Enter") {
            onEnter();
        }
    };
    return (
        <div className="TextInput">
            <div className="TextInput-container">
                {label && <label>{label}</label>}
                <input
                    type={type}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={onEnterKeyDown}
                    value={value}
                />
            </div>
        </div>
    );
}
