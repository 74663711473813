import { ChangeEvent } from "react";

type ChooseFileProps = {
    files: Array<string>;
    setFile: (fileName: string) => void;
    initialValue: string;
};

function ChooseFile({ files, setFile, initialValue }: ChooseFileProps) {
    const onFileChange = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setFile(e.target.value);
    };

    return (
        <div className="App-blok App-glass ChooseFile">
            Change file
            <select onChange={onFileChange}>
                {files.map((fileName) => (
                    <option
                        value={fileName}
                        defaultValue={(fileName === initialValue).toString()}
                        key={fileName}
                    >
                        {fileName}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default ChooseFile;
