import ChangePassword from "./ChangePassword";
import Documents from "./Documents";

import "./settings.css";
import Train from "./Train";
import UserInfo from "./UserInfo";

export default function Settings() {
    return (
        <div className="Settings">
            <UserInfo></UserInfo>
            <ChangePassword></ChangePassword>
            <div>
                <Documents></Documents>
            </div>

            <Train></Train>
        </div>
    );
}
