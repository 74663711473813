function fileNameFromHeaders(res: Response): string {
    return res.headers
        .get("content-disposition")
        .split("filename=")[1]
        .split(";")[0];
}

function downloadPopup(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export { fileNameFromHeaders, downloadPopup };
