import { useRequest } from "api";
import HeaderBlok from "bloks/HeaderBlok";
import { useState } from "react";
import { tokenSelector } from "state/router";
import { useAppSelector } from "state/store";
import Button from "widgets/Button";
import TextInput from "widgets/TextInput";

export default function ChangePassword() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const request = useRequest();
    const token = useAppSelector(tokenSelector);

    const theSame =
        newPassword == repeatNewPassword && newPassword && repeatNewPassword;

    const changePassword = () => {
        if (
            newPassword === repeatNewPassword &&
            window.confirm("Do you really want to change passwords?")
        ) {
            request("get", "changePassword", {
                oldPassword,
                newPassword,
            });
        }
    };

    return (
        <HeaderBlok title="Change Password">
            <div className="ChangePassword">
                <TextInput
                    setValue={setOldPassword}
                    value={oldPassword}
                    label="Old Password"
                    type="password"
                ></TextInput>
                <TextInput
                    setValue={setNewPassword}
                    value={newPassword}
                    label="New Password"
                    type="password"
                ></TextInput>
                <TextInput
                    setValue={setRepeatNewPassword}
                    value={repeatNewPassword}
                    label={
                        theSame
                            ? "Well Done"
                            : repeatNewPassword
                            ? "Not Yet"
                            : "Repeat New Password"
                    }
                    type="password"
                ></TextInput>
                <Button onClick={(e) => changePassword()}>Change</Button>
            </div>
        </HeaderBlok>
    );
}
