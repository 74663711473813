import { useEffect, useRef, useState } from "react";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as pdfjs from "pdfjs-dist";
import { MetaData } from "./Editor";

function SimpleFileViewer({ fileUrl }: { fileUrl: string }) {
    return (
        <div className="SimpleFileViewer App-blok App-glass">
            <object data={fileUrl}></object>
        </div>
    );
}

interface FileViewerProps {
    file: File;
    meta: MetaData;
}

function FileViewer({ file, meta }: FileViewerProps) {
    const canvasRef = useRef<HTMLCanvasElement>();
    const containerRef = useRef<HTMLDivElement>();

    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [pdfDocument, setPdfDocument] = useState<pdfjs.PDFDocumentProxy>();
    const [pdfPage, setPdfPage] = useState<pdfjs.PDFPageProxy>();
    const renderTaskRef = useRef<pdfjs.RenderTask>();

    const dpRatio = window.devicePixelRatio || 1;

    const calculateScale = () => {
        if (!pdfPage) return;

        const containerComputedStyle = window.getComputedStyle(
            containerRef.current
        );
        const canvasWidth =
            containerRef.current.clientWidth -
            parseFloat(containerComputedStyle.paddingLeft) -
            parseFloat(containerComputedStyle.paddingRight);

        const scale = canvasWidth / pdfPage.getViewport({ scale: 1.0 }).width;
        setScale(scale);
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

        const resizeObserver = new ResizeObserver(calculateScale);
        resizeObserver.observe(containerRef.current);

        const onWindowResize = (_: Event) => calculateScale();
        window.addEventListener("resize", onWindowResize);

        return () => {
            resizeObserver.disconnect();
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    useEffect(() => {
        const url = URL.createObjectURL(file).toString();
        pdfjs.getDocument(url).promise.then((pdf) => {
            setPdfDocument(pdf);
        });
    }, [file]);

    useEffect(() => {
        pdfDocument &&
            pdfDocument.getPage(pageNumber).then((page) => {
                setPdfPage(page);
            });
    }, [pdfDocument, pageNumber]);

    useEffect(calculateScale, [pdfPage]);

    useEffect(() => {
        if (!pdfPage) return;

        const viewport = pdfPage.getViewport({ scale });
        const canvas = canvasRef.current;

        canvas.width = Math.floor(viewport.width * dpRatio);
        canvas.height = Math.floor(viewport.height * dpRatio);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height = Math.floor(viewport.height) + "px";
    }, [scale]);

    useEffect(() => {
        if (!pdfPage) return;

        const viewport = pdfPage.getViewport({ scale });
        const canvasContext = canvasRef.current.getContext("2d");
        const transform = dpRatio !== 1 ? [dpRatio, 0, 0, dpRatio, 0, 0] : null;

        canvasContext.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
        );

        const renderContext = {
            canvasContext,
            transform,
            viewport,
        };

        renderTaskRef.current && renderTaskRef.current.cancel();
        renderTaskRef.current = pdfPage.render(renderContext);
        renderTaskRef.current.promise.then(
            () => {
                renderTaskRef.current = null;
                drawFields();
            },
            (error) => {
                renderTaskRef.current = null;
            }
        );
    }, [pdfPage, scale]);

    const drawFields = () => {
        if (!canvasRef.current) return;
        if (!pdfPage) return;

        console.log(
            pdfPage.view,
            canvasRef.current.width,
            canvasRef.current.height
        );

        const verticalOffsetText = 10;
        const context = canvasRef.current.getContext("2d");
        context.fillStyle = "red";
        context.strokeStyle = "red";
        context.lineWidth = 3;
        context.font = "2rem Arial";

        Object.entries(meta).map(([name, { xmin, xmax, ymin, ymax }]) => {
            context.strokeRect(xmin, ymin, xmax - xmin, ymax - ymin);
            context.fillText(name, xmin, ymin - verticalOffsetText);
        });
    };

    useEffect(drawFields, [meta]);

    return (
        <div className="FileViewer App-blok App-glass">
            <div className="FileViewer-container" ref={containerRef}>
                <canvas ref={canvasRef}></canvas>
            </div>
        </div>
    );
}

export default FileViewer;
export { SimpleFileViewer };
