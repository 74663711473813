import { useState, MouseEvent, KeyboardEvent } from "react";
import ErrorBlok from "bloks/ErrorBlok";
import "./Login.css";
import { useRequest } from "api";
import { useAppDispatch, useAppSelector } from "state/store";
import TextInput from "widgets/TextInput";
import Button from "widgets/Button";
import { errorSelector } from "state/errors";
import { login } from "state/router";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useAppDispatch();
    const error = useAppSelector(errorSelector);
    const request = useRequest();

    const onLogin = () => {
        let fd = new FormData();
        fd.append("username", username);
        fd.append("password", password);

        request("post", "login", {}, fd).then(async (res) =>
            dispatch(login(await res.text()))
        );
    };

    return (
        <div className="Login">
            <div className="App-blok App-glass">
                <form className="Login-form">
                    <TextInput
                        setValue={setUsername}
                        onEnter={onLogin}
                        label="Username"
                        value={username}
                    />
                    <TextInput
                        setValue={setPassword}
                        onEnter={onLogin}
                        label="Password"
                        value={password}
                        type="password"
                    />
                    <Button onClick={onLogin}>Login</Button>
                </form>
            </div>
            {error && <ErrorBlok>{error}</ErrorBlok>}
        </div>
    );
}

export default Login;
