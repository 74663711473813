import { MouseEvent, ReactNode } from "react";
import "./widgets.css";

type ChooseProps = {
    value: any;
    setValue: (to: string) => void;
    options: { [name: string]: ReactNode };
    numberOfColumns?: number;
    canBeNone?: boolean;
};

export default function Choose({
    value,
    setValue,
    options,
    numberOfColumns = 3,
    canBeNone = true
}: ChooseProps) {
    const onChoose = (e: MouseEvent<HTMLImageElement>) => {
        if (value === (e.currentTarget as HTMLElement).dataset.name && canBeNone) {
            setValue(null);
            return;
        }
        setValue((e.currentTarget as HTMLElement).dataset.name);
    };

    return (
        <div
            className="Choose"
            style={{ gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)` }}
        >
            {Object.entries(options).map(([name, node]) => {
                return (
                    <div
                        className="Form-clickable Choose-option"
                        data-name={name}
                        data-checked={value == name}
                        onClick={onChoose}
                        key={name}
                    >
                        {node}
                    </div>
                );
            })}
        </div>
    );
}
