import Choose from "widgets/Choose";
import csvIcon from "icons/csv.png";
import excelIcon from "icons/excel.png";
import jsonIcon from "icons/json.png";
import { MouseEvent } from "react";

function OutputType({ outputType, setOutputType }) {
    const onOutTypeClicked = (e: MouseEvent<HTMLImageElement>) => {
        setOutputType((e.target as HTMLElement).dataset.name);
    };

    return (
        <div className="Form-options App-blok App-glass">
            Which type of output do you want?
            <Choose
                value={outputType}
                setValue={setOutputType}
                options={{
                    csv: (
                        <img
                            src={csvIcon}
                            alt="Csv"
                            className="Form-filetype"
                        />
                    ),
                    excel: (
                        <img
                            src={excelIcon}
                            alt="Excel"
                            className="Form-filetype"
                        />
                    ),
                    json: (
                        <img
                            src={jsonIcon}
                            alt="Json"
                            className="Form-filetype"
                        />
                    ),
                }}
            />
        </div>
    );
}

export default OutputType;
