import DropDownBlok from "bloks/DropDownBlok";

import { useState } from "react";
import Button from "widgets/Button";
import { Usage as UsageType, useRequest, useLoad } from "api";
import Choose from "widgets/Choose";

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export default function Usage({ userNames }) {
    const request = useRequest();
    const [userName, setUserName] = useState<string>(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const usage: { [userName: string]: Array<UsageType> } = useLoad(
        userNames
            .then((userNames: Array<string>) =>
                userNames.map((userName: string) =>
                    request("get", "usage", { userName })
                        .then((res) => res.json())
                        .then((usage) => [userName, usage])
                )
            )
            .then((usage) => Promise.all(usage))
            .then((usage) => Object.fromEntries(usage))
    );

    let months = new Set();
    let years = new Set();
    usage &&
        Array.from(Object.values(usage)).forEach((usage) =>
            usage.forEach(({ date }) => {
                let dateParsed = new Date(date);
                months.add(dateParsed.getMonth());
                years.add(dateParsed.getFullYear());
            })
        );

    const filteredUsage =
        usage && userName
            ? usage[userName].filter((u) => {
                  let date = new Date(u.date);
                  return (
                      (month == null || date.getMonth() == month) &&
                      (year == null || date.getFullYear() == year)
                  );
              })
            : [];

    return (
        usage && (
            <DropDownBlok title="Usage">
                <div className="Usage">
                    <UserPicker
                        users={Object.keys(usage)}
                        setUserName={setUserName}
                    />

                    <Choose
                        value={month}
                        setValue={setMonth}
                        options={Object.fromEntries(
                            Array.from(months).map((m: number) => [
                                m,
                                monthNames[m],
                            ])
                        )}
                    ></Choose>

                    <Choose
                        value={year}
                        setValue={setYear}
                        options={Object.fromEntries(
                            Array.from(years).map((y) => [y, y])
                        )}
                    ></Choose>

                    {userName && (
                        <UsageOfUser usage={filteredUsage}></UsageOfUser>
                    )}
                </div>
            </DropDownBlok>
        )
    );
}

function UserPicker({ users, setUserName }) {
    return (
        <div className="UserPicke">
            <div className="UserPicke-container">
                {users.map((userName) => (
                    <Button
                        onClick={() => setUserName(userName)}
                        key={userName}
                    >
                        {userName}
                    </Button>
                ))}
            </div>
        </div>
    );
}

function UsageOfUser({ usage }) {
    return (
        <div className="UsageOfUser">
            <div className="UsageOfUser-total">
                <p>Total</p>
                <h3>
                    {usage
                        .map(({ count }) => count)
                        .reduce((acc, el) => acc + el, 0)}
                </h3>
            </div>

            {usage.map(({ count, date }) => (
                <div className="UsageOfUser-element" key={date}>
                    <div className="UsageOfUser-count">{count}</div>
                    <div className="UsageOfUser-date">{date}</div>
                </div>
            ))}
        </div>
    );
}
