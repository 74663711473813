import ErrorBlok from "bloks/ErrorBlok";
import { MouseEvent } from "react";
import ButtonBlok from "bloks/ButtonBlok";
import { useAppSelector } from "state/store";
import { useDispatch } from "react-redux";
import {
    changeExtractionRoute,
    ErrorRoute,
    extractionRouteSelector,
    ExtractSubRouteName,
} from "state/router";
import { clearError } from "state/errors";

function Error() {
    const { error } = useAppSelector(extractionRouteSelector) as ErrorRoute;
    const dispatch = useDispatch();

    const onGoHome = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatch(clearError());
        dispatch(changeExtractionRoute({ name: ExtractSubRouteName.Options }));
    };

    return (
        <>
            <ErrorBlok>{error}</ErrorBlok>
            <ButtonBlok onClick={onGoHome}>Home</ButtonBlok>
        </>
    );
}

export default Error;
