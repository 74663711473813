import { useEffect, useState } from "react";
import { setError } from "state/errors";
import { tokenSelector } from "state/router";
import { useAppDispatch, useAppSelector } from "state/store";

export type Usage = { count: number; date: any };
export type UserInfo = {
    firstName?: string;
    lastName?: string;
    company?: string;
    email?: string;
    phone?: string;
};

type ExtractionResponse = {
    [filename: string]: FileExtraction;
};

type FileExtraction = {
    pages: Array<PageExtraction>;
};

type PageExtraction =
    | {
          fields: Array<FieldExtraction>;
      }
    | { validationUrl: string };

type FieldExtraction = {
    label: string;
    text: string;
    score: number;
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
};

export type {
    ExtractionResponse,
    FileExtraction,
    FieldExtraction,
    PageExtraction,
};

export const apiUrl =
    process.env.NODE_ENV == "production"
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.REACT_APP_API_URL_DEV;

export function useLoad<T>(valuePromise: Promise<T>) {
    const [value, setValue] = useState<T | null>(null);

    useEffect(() => {
        (async function () {
            setValue(await valuePromise);
        })();
    }, []);

    return value;
}

export function useLoadWithSetter<T>(valuePromise: Promise<T>) {
    const [value, setValue] = useState<T | null>(null);

    useEffect(() => {
        (async function () {
            setValue(await valuePromise);
        })();
    }, []);

    return [value, setValue];
}

export function useRequest() {
    const token = useAppSelector(tokenSelector);
    const dispatch = useAppDispatch();

    return async (
        method: string,
        endpoint: string,
        args: { [key: string]: string | number | boolean } = {},
        body: any = null
    ): Promise<any | undefined> => {
        try {
            let options: any = {
                method,
            };

            if (body instanceof FormData) {
                options.body = body;
            } else if (body !== null) {
                options.body = JSON.stringify(body);
                options.headers = {
                    "Content-Type": "application/json",
                };
            }

            const res = await fetch(
                apiUrl +
                    endpoint +
                    "?" +
                    new URLSearchParams({ ...args, token }),
                options
            );

            switch (res.status) {
                case 200:
                    return res;
                case 401:
                    dispatch(
                        setError(
                            "Sorry, your credentials were invalid or you're token has expired ⏰."
                        )
                    );
                    break;

                case 400:
                    dispatch(
                        setError(
                            "There is something wrong with this website 😕"
                        )
                    );
                    break;

                case 404:
                    dispatch(setError("No response from the server, sorry 🥲"));
                    break;

                case 500:
                    dispatch(
                        setError("Something went wrong in the server 😢...")
                    );
                    break;

                default:
                    dispatch(setError("Something went wrong 😢..."));
                    break;
            }
        } catch (error) {
            dispatch(setError("Do you have an internet connection? 🙄"));
        }
    };
}
