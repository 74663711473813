import { HTMLProps, MouseEvent, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { FieldData, MetaData } from './Editor';

interface EntriesProps {
    data: FieldData;
    meta: MetaData;
    fields: Array<string> | 'all';
}

const deltaHeightDifference = 4;
const minScoreForBold = 80;

function Entries({ data, fields, meta }: EntriesProps) {
    const newFieldSelectorRef = useRef();
    const [dummy, setDummy] = useState(true);
    const reDraw = () => setDummy(!dummy);

    console.log(fields)
    console.log(data)

    const missingFields =
        fields == 'all'
            ? []
            : fields.filter(
                  (label: string) => !Object.keys(data).includes(label)
              );

    const onAddField = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (newFieldSelectorRef.current) {
            data[(newFieldSelectorRef.current as HTMLSelectElement).value] = '';
            reDraw();
        }
    };

    const onRemoveField = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const field = (e.target as HTMLDivElement).dataset.value;
        if (
            data[field].length == 0 ||
            window.confirm(`You sure you want to delete field ${field}?`)
        ) {
            delete data[field];
            reDraw();
        }
    };

    return (
        <form className="Entries App-blok App-glass">
            {Object.entries(data)
                .sort(([fieldName1, _], [fieldName2, __]) => {
                    const meta1 = meta[fieldName1];
                    const meta2 = meta[fieldName2];
                    if (!meta1) {
                        return 1;
                    } else if (!meta2) {
                        return -1;
                    }

                    if (meta1.pageNumber === meta2.pageNumber) {
                        return Math.abs(meta1.ycenter - meta2.ycenter) <
                            deltaHeightDifference // if on same height
                            ? meta1.xcenter - meta2.xcenter // look at x value
                            : meta1.ycenter - meta2.ycenter; // else y value
                    } else {
                        return meta1.pageNumber - meta2.pageNumber;
                    }
                })
                .filter(([k, _]) =>
                    fields == 'all' ? true : fields.includes(k)
                )
                .map(([k, v]) => {
                    let label;
                    if (meta[k]) {
                        const scoreP = Math.round(meta[k].score * 100);
                        const labelText = `${k} (${scoreP}%)`;
                        label =
                            scoreP > minScoreForBold ? (
                                labelText
                            ) : (
                                <b>{labelText}</b>
                            );
                    } else {
                        label = k;
                    }

                    return (
                        <Fragment key={k + v}>
                            <label htmlFor={k}>{label}</label>
                            <TextArea
                                name={k}
                                defaultValue={v}
                                onChange={(e) => {
                                    const ta = e.target as HTMLTextAreaElement;
                                    data[k] = ta.value;
                                    resizeTextAreaToContent(ta);
                                }}
                            />
                            <AddRemoveButton
                                onClick={onRemoveField}
                                data-value={k}
                            >
                                -
                            </AddRemoveButton>
                        </Fragment>
                    );
                })}
            {missingFields.length > 0 && (
                <>
                    <label>Add a field</label>
                    <select ref={newFieldSelectorRef}>
                        {missingFields.map((label: string) => (
                            <option key={label} value={label}>
                                {label}
                            </option>
                        ))}
                    </select>
                    <AddRemoveButton onClick={onAddField}>+</AddRemoveButton>
                </>
            )}
        </form>
    );
}

function AddRemoveButton(props: HTMLProps<HTMLDivElement>) {
    return <div className="AddRemoveButton" {...props}></div>;
}

function TextArea(props: HTMLProps<HTMLTextAreaElement>) {
    const ref = useRef(null);
    useEffect(() => {
        resizeTextAreaToContent(ref.current);
    }, [ref]);
    return <textarea {...props} ref={ref} />;
}

function resizeTextAreaToContent(e: HTMLTextAreaElement) {
    e.style.height = '';
    e.style.height = e.scrollHeight + 'px';
}

export default Entries;
