const base = window.location.origin + '/api';
const extractionResponse = `{
    "FILENAME": {
        "pages": [
            {
                "validationUrl": URL_TO_VALIDATE,
                "fields": [
                    {
                        "label": "shipper_name",
                        "page_no": 0,
                        "score": 0.9180408,
                        "status": "correctly_predicted",
                        "text": "DALIAN NORTH -SHIPPING INTERNATIONAL TRADING CO.LTD .",
                        "xmax": 1075,
                        "xmin": 88,
                        "ymax": 265,
                        "ymin": 227
                    },
                    {
                        "label": "shipper_address",
                        "page_no": 0,
                        "score": 0.61955285,
                        "status": "correctly_predicted",
                        "text": "NO.16 , ZHUQING STREET ZHONGSHAN DISTRICT , DALIAN ,PR CHINA",
                        "xmax": 999,
                        "xmin": 87,
                        "ymax": 337,
                        "ymin": 268
                    }
                ],
                "tables": [
                    {
                        "cells": [
                            {
                                "col": 1,
                                "col_span": 1,
                                "row": 3,
                                "row_label": "",
                                "row_span": 1,
                                "score": 0.89453125,
                                "text": "Due Date :",
                                "verification_status": "correctly_predicted",
                                "xmax": 486,
                                "xmin": 430,
                                "ymax": 260,
                                "ymin": 246
                            },
                            {
                                "col": 2,
                                "col_span": 1,
                                "row": 3,
                                "row_label": "",
                                "row_span": 1,
                                "score": 0.9140625,
                                "text": "12/01/18",
                                "verification_status": "correctly_predicted",
                                "xmax": 550,
                                "xmin": 486,
                                "ymax": 260,
                                "ymin": 246
                            }
                        ]
                    }
                ]
            }
        ]
    }
}`;

const convertExtractionResponseCode = `
function convertExtractionResponse(extractionResponse) {
  return Object.entries(extractionResponse).map(([fileName, fileExtraction]) => {
      let dataPoint = { fileName };
      fileExtraction.pages.forEach((page) => {
          page.fields.forEach((field) => {
              dataPoint[field.label] = field.text;
          });
      });
      return dataPoint;
  });
}
`;

const fieldsResponse = `{
    MarksAndNumbers: true,
    SCAC: true,
    billto_address: true,
    billto_name: true,
    bl_number: true,
    carrier_address: true,
    carrier_name: true,
    consignee_address: true,
    consignee_name: true,
    container_number: true,
    currency: true,
    date_issue: true,
    date_onboard: true,
    declared_value: true,
    forwarding_agent_address: true,
    forwarding_agent_name: true,
    freight_charges: true,
    hs_code: true,
    notify_party_address: true,
    notify_party_name: true,
    package_content: true,
    place_of_delivery: true,
    place_of_issue: true,
    place_of_receipt: true,
    port_of_discharge: true,
    port_of_loading: true,
    seal_number: true,
    shipper_address: true,
    shipper_name: true,
    total_gross_weight: true,
    total_measurement: true,
    total_net_weight: true,
    total_num_containers: true,
    total_packages: true,
    vessel: true,
}`;

export default function Dev() {
    return (
        <div className="App-blok App-glass">
            <h2>Using our API</h2>
            <p>
                The base URL you're going to be using is the same as this
                website <code>{base}</code>, and there's two main endpoints
                you're going to be using, as well as some supplementary ones.
            </p>

            <Part title="Authentication">
                <p>
                    To extract data from your documents, you first have to
                    obtain a token from <code>/login</code>. This endpoint asks
                    for a form encoded body, with a username and password and
                    returns your token as plain text. Using cURL, this can be
                    done like so.
                </p>
                <div className="App-code">
                    <code>
                        $ curl "{base}/login" <br />
                        -X POST <br />
                        -H "Content-Type: application/x-www-form-urlencoded"{' '}
                        <br />
                        -d "username=USERNAME&password=PASSWORD" <br /> <br />
                        <pre>
                            eyJhbGciOiJIUzI1NiIsInR5cCIIkpXVCJ9.eyJleHBpcmVzIjoxNjc3ODU0NTg3LjE3M
                            <br />
                            zI1NCwidXNlcm5hbWUiOiJoZW5rIn0.59o6JP6fSjJgkHBU-1_DSj1liClIw5-qyiZJm7wOLRU
                        </pre>
                    </code>
                </div>
            </Part>

            <Part title="Extraction">
                <p>
                    Once you have obtained a token, you can use it to access
                    endpoint <code>/extract</code>. You encode the token in the
                    URL, and your files need to be form-encoded. It returns some
                    json, with all the extracted fields including an extraction
                    score and some info on where the field is found. If you
                    would like to use the new One Shot model, you can optionally
                    set the <code>oneShot</code> in the url parameter to{' '}
                    <code>true</code>. If you would like to validate the
                    extraction and improve the model, set the{' '}
                    <code>validate</code> parameter to <code>true</code>.
                </p>
                <div className="App-code">
                    <code>
                        $ curl {base}/extract?token=TOKEN&oneShot=false&validate=true <br />
                        -X POST <br />
                        -H "Content-Type: multipart/form-data" <br />
                        -F "FILENAME=@PATH_TO_FILE" <br />
                    </code>
                </div>
                <p>The result.</p>
                <div className="App-code">
                    <code>
                        <pre>{extractionResponse}</pre>
                    </code>
                </div>
            </Part>

            <Part title="Conversion">
                <p>
                    The extraction api return data in json format with metadata,
                    like locations and prediction scores. But in case you would
                    like to convert it to another table format like excel or
                    csv, you can use our <code>/convert</code> endpoint. Encode
                    the <code>outputType</code> in the URL (<code>excel</code>{' '}
                    or <code>csv</code> for now), and pass your data as json.
                    The response from <code>/extract</code> isn't ready yet, as
                    the metadata is not wanted, but this is easy to remove.
                </p>

                <div className="App-code">
                    <code>
                        <pre>{convertExtractionResponseCode}</pre>
                    </code>
                </div>

                <p>
                    This will result in a table where every row is one file,
                    with the fields as columns (and one for the file name
                    itself).
                </p>

                <div className="App-code">
                    <code>
                        $ curl {base}/convert?token=TOKEN&outputType=excel
                        <br />
                        -X POST <br />
                        -H "Content-Type: application/json" <br />
                        -d "DATA" --output fields.xls
                    </code>
                </div>
            </Part>

            <Part title="Filtering Fields">
                <p>
                    In the portal you have to select the kind of document that's
                    provided before the data can be extracted. Even though every
                    document is extracted by the same model which doesn't take
                    into account the type of the document, this makes sure no
                    unwanted fields are shown (even if the model has found one)
                    when correcting the output. If you also want to use this
                    functionality to filter out unwanted fields, you can call
                    into the <code>/fields</code> api, providing the type of
                    document. Any field that should not be in the document would
                    have a <code>false</code> as it's value.
                </p>

                <div className="App-code">
                    <code>
                        $ curl {base}
                        /fields?token=TOKEN&documentType=DOCUMENTTYPE
                        <pre>{fieldsResponse}</pre>
                    </code>
                </div>
            </Part>
        </div>
    );
}

function Part({ title, children }) {
    return (
        <>
            <h4>{title}</h4>
            {children}
        </>
    );
}
