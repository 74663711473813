import {
    useRef,
    ChangeEvent,
    DragEvent,
    Dispatch,
    SetStateAction,
} from "react";
import folderIcon from "icons/folder.png";
import fileIcon from "icons/file.png";
import findIcon from "icons/find.png";

type FilesProps = {
    setFiles: Dispatch<SetStateAction<Array<File>>>;
    chooseFolder: boolean;
    setChooseFolder: Dispatch<SetStateAction<boolean>>;
    files: Array<File>;
};

function Files({ setFiles, chooseFolder, setChooseFolder, files }: FilesProps) {
    const fileBrowseRef = useRef<HTMLInputElement>(null);

    const onFilesChanged = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) setFiles(Array.from(e.target.files));
    };

    const onFilesDropped = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setFiles(Array.from(e.dataTransfer.files));
    };

    const onBrowseFiles = (_: any) => {
        if (fileBrowseRef.current) fileBrowseRef.current.click();
    };

    let messageOrFiles;
    const nFilesShowm = 4;
    if (files) {
        messageOrFiles = files
            .slice(0, nFilesShowm)
            .map((file: File) => <p key={file.name}>{file.name}</p>);
        if (nFilesShowm < files.length) {
            messageOrFiles.push(
                <p key="dotdotdot">... ({files.length - nFilesShowm})</p>
            );
        }
    } else {
        messageOrFiles = (
            <p>
                Drop your {chooseFolder ? "folders" : "files"} here, or click to
                browse for them.
            </p>
        );
    }

    return (
        <div
            className="Form-file Form-clickable App-blok App-glass"
            onClick={onBrowseFiles}
            onDrop={onFilesDropped}
            onDragOver={(e) => e.preventDefault()}
        >
            <div
                className="Form-swap"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setChooseFolder(!chooseFolder);
                }}
            >
                <img src={chooseFolder ? fileIcon : folderIcon} />
            </div>
            <input
                ref={fileBrowseRef}
                type="file"
                onChange={onFilesChanged}
                hidden
                multiple
                webkitdirectory={chooseFolder ? "" : null}
                directory={chooseFolder ? "" : null}
            />
            <img src={findIcon} />
            {messageOrFiles}
        </div>
    )
}

export default Files;
