import { ReactNode } from "react";

type ErrorBlokProps = { children: ReactNode };

function ErrorBlok({ children }: ErrorBlokProps) {
    return (
        children && (
            <div className="App-blok App-glass ErrorBlok">
                <div className="ErrorBlok-content">{children}</div>
            </div>
        )
    );
}

export default ErrorBlok;
