import { useRequest, UserInfo, useLoad } from "api";
import DropDownBlok from "bloks/DropDownBlok";
import trashIcon from "icons/trash.svg";
import { Fragment, useState } from "react";
import { tokenSelector } from "state/router";
import { useAppSelector } from "state/store";
import { camelToeToSpaced } from "utils";

export default function Users({ userNames }) {
    const request = useRequest();
    const info: { [userName: string]: UserInfo } = useLoad(
        userNames
            .then((userNames) =>
                userNames.map((userName) =>
                    request("get", "userInfo", { userName })
                        .then((userInfo) => userInfo.json())
                        .then((userInfo) => [userName, userInfo])
                )
            )
            .then((info) => Promise.all(info))
            .then((info) => Object.fromEntries(info))
    );

    return (
        info && (
            <DropDownBlok title="Users">
                <div className="Users">
                    {Object.entries(info).map(([userName, userInfo]) => (
                        <div key={userName} className="User-container">
                            <User
                                userName={userName}
                                userInfo={userInfo}
                            ></User>
                        </div>
                    ))}
                </div>
            </DropDownBlok>
        )
    );
}

function User({ userName, userInfo }) {
    const request = useRequest();
    const [removed, setRemoved] = useState(false);

    const removeUser = () => {
        if (
            window.confirm(`Are you sure you want to remove user ${userName}?`)
        ) {
            request("get", "removeUser", { userName });
            setRemoved(true);
        }
    };

    return (
        !removed && (
            <div className="User">
                <div className="User-userName">
                    {userName}{" "}
                    <img src={trashIcon} onClick={(e) => removeUser()} />
                </div>
                <div className="User-attributes">
                    {Object.entries(userInfo).map(
                        ([key, value]) =>
                            value && (
                                <Fragment key={key}>
                                    <div className="User-attributeName">
                                        {camelToeToSpaced(key)}
                                    </div>
                                    <div className="User-attributeValue">
                                        {value as string}
                                    </div>
                                </Fragment>
                            )
                    )}
                </div>
            </div>
        )
    );
}
