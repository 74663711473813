import { ReactNode, useState } from "react";
import upIcon from "icons/up.svg";
import downIcon from "icons/down.svg";
import "./blok.css";

type DropDownBlokProps = {
    title: string;
    children: ReactNode;
    headerChildren?: ReactNode;
    startsDropped?: boolean;
};

export default function DropDownBlok({
    title,
    children,
    headerChildren = null,
    startsDropped = false,
}: DropDownBlokProps) {
    const [show, setShow] = useState(startsDropped);

    return (
        <div className="DropDownBlok App-blok App-glass">
            <div className="blok-header">
                {title}
                <div
                    onClick={(_) => setShow(!show)}
                    className="DropDownBlok-headerToggle"
                >
                    {show ? <img src={upIcon} /> : <img src={downIcon} />}
                </div>
            </div>
            {headerChildren && (
                <div className="block-content">{headerChildren}</div>
            )}

            {show && <div className="blok-content">{children}</div>}
        </div>
    );
}
