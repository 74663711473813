import novadocLogo from "imgs/novadocLogo.png";
import devIcon from "icons/dev.png";
import adminIcon from "icons/admin.svg";
import settingsIcon from "icons/settings.png";
import extractIcon from "icons/file.png";
import "./Header.css";
import { useEffect, useRef } from "react";
import { MouseEvent } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import {
    adminSelector,
    changeRoute,
    AuthenticatedSubRouteName,
} from "state/router";

interface HeaderProps {
    isAuth: boolean;
}

function Header({ isAuth }: HeaderProps) {
    const h1Ref = useRef(null);
    const h3Ref = useRef(null);

    const dispatch = useAppDispatch();
    const isAdmin = useAppSelector(adminSelector);

    const onChangeRoute = (e: MouseEvent<HTMLImageElement>) =>
        dispatch(
            changeRoute(
                (e.target as HTMLElement).dataset
                    .name as AuthenticatedSubRouteName
            )
        );

    useEffect(() => {
        if (h1Ref.current && h3Ref.current) {
            (h3Ref.current as HTMLElement).style.width =
                (h1Ref.current as HTMLElement).offsetWidth + "px";
        }
    }, [isAuth, h1Ref, h3Ref]);

    return (
        <header className="Header">
            <div className="Header-top">
                <div className="Header-left">
                    <h1 ref={h1Ref}>BILL OF LADING TABELIZER</h1>
                    {isAuth && (
                        <>
                            <img
                                src={extractIcon}
                                alt="Extract"
                                data-name="extract"
                                onClick={onChangeRoute}
                            />
                            <img
                                src={settingsIcon}
                                alt="Settings"
                                data-name="settings"
                                onClick={onChangeRoute}
                            />
                            {isAdmin && (
                                <img
                                    src={adminIcon}
                                    alt="Admin"
                                    data-name="admin"
                                    onClick={onChangeRoute}
                                />
                            )}
                            <img
                                src={devIcon}
                                alt="Dev"
                                data-name="dev"
                                onClick={onChangeRoute}
                            />
                        </>
                    )}
                </div>
                {/* {process.env.REACT_APP_ADD_LOGO === "1" ? (
                    <img className="Header-logo" src={novadocLogo}></img>
                ) : (
                    <div></div>
                )} */}
            </div>
            {!isAuth && (
                <h3 ref={h3Ref}>
                    This tool can be used to convert Bill of Lading's to a table
                    format. The input can be pdf's or images, or a combination
                    of both, and you can convert it to the format that suits
                    your needs: csv, excel or json.
                </h3>
            )}
        </header>
    );
}

export default Header;
