import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export const errorSlice = createSlice({
    name: "error",
    initialState: null as string,
    reducers: {
        setError: (_, action: PayloadAction<string>) => action.payload,
        clearError: () => null,
    },
});

export const { setError, clearError } = errorSlice.actions;

export const errorSelector = (state: RootState) => state.errors;